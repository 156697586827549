// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'

// Step 2: Define your component
const AboutPage = () => {
    return (
        <Layout pageTitle="About this scrapbook">
            <p>All the pictures on this website are my own work, using various media 
            including photographs, pencil, watercolor, oils, and an Ipad.</p>
        </Layout>
    )
}

// Step 3: Export your component
export default AboutPage